import React, { useEffect } from "react";
import MainLayout from "../components/MainLayout";
import ShowcaseOverlay from "../components/ShowcaseOverlay/ShowcaseOverlay";
import useToggler from "../hooks/toggler";
import "../styles/home.scss";
import isBrowser from "../utils/isBrowser";

const Home = () => {
  const vezToggler = useToggler(false);
  const featureToggler = useToggler(true);

  useEffect(() => {
    if (sessionStorage.getItem("vez_loaded") === "true") {
      const showVez = vezToggler.on;
      showVez();
    } else {
      const hideVez = vezToggler.off;
      hideVez();
    }
  }, [vezToggler.on, vezToggler.off]);

  useEffect(() => {
    if (isBrowser()) {
      const onMessage: (ev: MessageEvent) => any = (e) => {
        if (e.data === "vez_loaded") {
          vezToggler.on();
        }
      };

      window.addEventListener("message", onMessage);

      return () => {
        window.removeEventListener("message", onMessage);
      };
    }
  }, [vezToggler.on]);

  return vezToggler.isOn ? (
    <ShowcaseOverlay showFeatures={featureToggler.isOn} />
  ) : null;
};

Home.Layout = MainLayout;

export default Home;
