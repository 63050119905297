import { Link } from "gatsby";
import React, { useEffect } from "react";
import "./ShowcaseOverlay.scss";
import { Accordion } from "react-bootstrap";
import useToggler from "../../hooks/toggler";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import isBrowser from "../../utils/isBrowser";
import { GrBlog } from "react-icons/gr";
import { GiNotebook } from "react-icons/gi";
import { AiOutlineInfoCircle } from "react-icons/ai";

interface ShowcaseOverlayProps {
  showFeatures?: boolean;
}

const routes = [
  {
    name: "360° models",
    path: "/360-models",
    icon: "/icons/showcase/360.svg",
  },
  {
    name: "Interactivity",
    path: "/interactivity",
    icon: "/icons/showcase/interactivity.svg",
  },
  {
    name: "Configurator",
    path: "/configurator",
    icon: "/icons/showcase/configurator.svg",
  },
  {
    name: "Augmented reality",
    path: "/augmented-reality",
    icon: "/icons/showcase/ar.svg",
  },
];
const ShowcaseOverlay: React.FC<ShowcaseOverlayProps> = (props) => {
  const { showFeatures } = props;
  const featureToggler = useToggler(showFeatures);
  const bottomButtonToggler = useToggler(true);
  const sideBarToggler = useToggler(true);

  useEffect(() => {
    if (isBrowser()) {
      const onMessage = (message: MessageEvent) => {
        if (message.data === "bottom_mobile_bar_closed") {
          bottomButtonToggler.on();
        } else if (message.data === "bottom_mobile_bar_opened") {
          bottomButtonToggler.off();
        } else if (message.data === "product_closed") {
          sideBarToggler.on();
        } else if (message.data === "product_viewed") {
          sideBarToggler.off();

        }
      };
      window.addEventListener("message", onMessage);

      return () => {
        window.removeEventListener("message", onMessage);
      };
    }
  }, []);

  return (
    <div
      className={`container d-flex justify-content-between showcase-overlay prevent-select`}
    >
      <Accordion
        defaultActiveKey={showFeatures ? "0" : undefined}
        style={{ width: "max-content" }}
        className="mb-auto"
      >
        {sideBarToggler.isOn && (
          <>
            <div
              className={`showcase-overlay-feature-toggler-wrapper ${
                !featureToggler.isOn ? "rounded-full" : ""
              }`}
            >
              <Accordion.Toggle
                onClick={() => {
                  if (featureToggler.isOn) {
                    setTimeout(() => {
                      featureToggler.off();
                    }, 300);
                  } else [featureToggler.on()];
                }}
                eventKey="0"
                className={`showcase-overlay-feature-toggler pointer-events-all`}
                style={{ pointerEvents: "all" }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  {featureToggler.isOn && <AiOutlineArrowLeft />}
                  <div className="mr-1">
                    {featureToggler.isOn ? "Dismiss" : "Feature"}
                  </div>
                  {!featureToggler.isOn && <AiOutlineArrowRight />}
                </div>
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse eventKey="0">
              <div className={`showcase-overlay-link-container`}>
                {routes.map((route) => (
                  <Link className="showcase-overlay-link-item" to={route.path}>
                    <button className="w-100 d-flex flex-column align-items-center justify-content-center">
                      <img src={route.icon} alt={route.name} />
                      <p>{route.name}</p>
                    </button>
                  </Link>
                ))}

                <Link to="/360-models">
                  <button className="button-primary w-100 px-0">
                    Explore features
                  </button>
                </Link>
              </div>
            </Accordion.Collapse>
          </>
        )}
      </Accordion>
      {bottomButtonToggler.isOn && (
        <div className="showcase-overlay-bottom-container d-flex pointer-events-all">
          <Link to="/case-studies">
            <button className="button-primary bg-white text-dark pt-2 pb-2 rounded-capsule d-flex align-items-center justify-content-center">
              <GiNotebook />
              <div className="ml-1 w-max">Case Studies</div>
            </button>
          </Link>
          <Link to="https://blog.helloar.io">
            <button className="button-primary bg-white text-dark pt-2 pb-2 rounded-capsule d-flex align-items-center justify-content-center">
              <GrBlog />
              <div className="ml-1 w-max">Blog</div>
            </button>
          </Link>
          <Link to="/about-us">
            <button className="button-primary bg-white text-dark pt-2 pb-2 rounded-capsule d-flex align-items-center justify-content-center">
              <AiOutlineInfoCircle />
              <div className="ml-1 w-max">About us</div>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default ShowcaseOverlay;
